import Vue from 'vue'

const menu = "humanresource/accessprofitpayment/";

export default {

    pagination(dtr, token) { return Vue.prototype.$http.post(menu + "pagination", dtr); },
    excel(dtr, token) {
        return Vue.prototype.$http.post(menu + "report", dtr, {
            responseType: "blob",
            headers: {
                "Content-Type": "application/json",
            },
        });
    },
    save(obj, usr) {
        console.log(obj);
        return Vue.prototype.$http.post(menu + "save", obj, { params: { requestID: usr } });
    },
    saveMassive(obj, usr) {
        console.log(obj);
        return Vue.prototype.$http.post(menu + "saveMassive", obj, { params: { requestID: usr } });
    },
}