<template>
  <v-container>
    <div v-if="showDialogAdd">
      <v-dialog v-model="showDialogAdd" width="80%">
        <s-toolbar
          color="primary"
          dark
          label="Carga Masiva de Programación de Utilidades"
          @close="showDialogAdd = false"
          close
        ></s-toolbar>
        <v-card>
          <v-container>
            <v-row>
              <v-col>
                <s-import-excel
                  @loaded="loaded($event)"
                  :headers="headersMassive"
                ></s-import-excel>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-dialog>
    </div>

    <v-row>
      <v-col>
        <s-crud ref="crud"
          :config="config"
          title="Utilidades"
          remove
          add :filter=filter
          add-no-function
          @add="addUtility()"
        >
        <template v-slot:AppStatus='{row}'>
           <v-chip x-small :color='row.AppStatus==2?"success":""'>
              {{row.AppStatus==1?'Pendiente':'Atendido'}}
           </v-chip>
        </template>
        <template v-slot:TypePayment='{row}'>
           <v-chip x-small :color='row.TypePayment==1?"info":""'>
              {{row.TypePayment==1?'EFECTIVO':'CHEQUE'}}
           </v-chip>
        </template>
        </s-crud>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import _sAccessProfitPayment from "@/services/HumanResource/AccessProfitPaymentService.js";
export default {
  name: "TimeRealReserve",
  components: {},
  props: {
    MltID: {
      type: Number,
      default: null,
    },
  },

  data: () => ({
    files: [],
    filter:{AreID:0},
    showDialogAdd: false,
    config: {
      service: _sAccessProfitPayment,
model:{AcsID:'ID',AppDate:'date',AppStatus:'',TypePayment:''},
      headers: [
        { text: "Trabajador", value: "FullName" },
        { text: "DNI", value: "AppDocumentNumber" },
        { text: "Fecha Programada", value: "AppDate" },
        { text: "Forma Pago", value: "TypePayment" },
        { text: "Grupo", value: "AppStand" },
        { text: "N° Cheque", value: "AppPaycheck" },
        { text: "Fila", value: "AppPayfile" },
         { text: "Estado", value: "AppStatus" },
      ],
    },
    headersMassive: [
      { text: "Trabajador", value: "TRABAJADOR", column: "TRABAJADOR" },
      { text: "Documento", value: "AppDocumentNumber", column: "DNI" },
      { text: "Forma de Pago", value: "TypePayment", column: "FORMA DE PAGO" },
      { text: "Grupo", value: "AppStand", column: "GRUPO" },
      {
        text: "Fecha Programada",
        value: "AppDate",
        column: "FECHA PROGRAMADA",
        date: true,
      },
      { text: "N° Cheque", value: "AppPaycheck", column: "N° DE CHEQUE" },
      { text: "Fila", value: "AppPayfile", column: "FILE" },
    ],
  }),
  methods: {
    loaded(table) {

      console.log("GUARDAR MASIVAAAAAAAAA",table);

      this.$fun.alert("Seguro de Cargar Datos?", "question").then((r) => {
        if (r.value) {
          table.map((item) => {
            item.TypePayment = item.TypePayment.includes("CHEQUE") || item.TypePayment == 2 ? 2 : 1;
            // return obj;
          });

          console.log("GUARDADOOOOOOOOOO",table);

          _sAccessProfitPayment
            .saveMassive(table, this.$fun.getUserID())
            .then((r) => {
              this.$fun.alert("Agregados Correctamente", "success");
              this.showDialogAdd = false;
              this.$refs.crud.refresh();
            });
        }
      });
    },
    addUtility() {
      this.showDialogAdd = true;
    },
    returnObject(item) {},
    start() {},
    cancel() {},
  },
  mounted() {},
  watch: {
    files() {
      console.log(this.files);
    },
  },
};
</script>
